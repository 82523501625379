html {
  font-size: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4a4a4a;
  font-family: lexia,Georgia,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration-style: dashed;
}

  a:hover {
    text-decoration: underline;
    text-decoration-style: solid;
  }

.error {
  color: #a52d00;
}

.notification {
  color: grey;
}
