.DeliverySiteBodyElementContent,
.DeliverySiteBodyElementContent-subText {
  display: flex;
  flex-grow: 1;
  flex-basis: 0; /* should be min-content but having to use 0 to support old iPhone */
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.DeliverySiteBodyElementContent-subText {
}

.DeliverySiteBodyElementContent-icon {
}

  .DeliverySiteBodyElementContent-icon img {
    position: relative;
  }

.DeliverySiteBodyElementContent-text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
}

.DeliverySiteBodyElementContent-subText .DeliverySiteBodyElementContent-text {
  color: #757575;
  font-size: .85rem;
  font-style: italic;
}

@media screen and (min-width: 320px) {
  .DeliverySiteBodyElementContent {
    flex-direction: row;
    gap: 16px;
  }
}

@media screen and (min-width: 768px) {
  .DeliverySiteBodyElementContent {
    font-size: 1.25rem;
  }
}
