.DeliverySiteBody {
}

.DeliverySiteBody-wrapper {
  flex-direction: column;
}

a.button {
  border: none;
  color: #ffffff;
  background-color: #af272f;
  padding: 5px 30px 1px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  font-family: lexia,Georgia,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  cursor: pointer;
}
