.DeliverySiteBodyElement {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px !important;
}

.DeliverySiteBodyElement-content {
  display: flex;
  flex-grow: 1;
  flex-basis: 0; /* should be min-content but having to use 0 to support old iPhone */
  flex-direction: column;
  gap: 4px;
}

.DeliverySiteBodyElement-icon {
  padding-right: 0.125em;
}

  .DeliverySiteBodyElement-icon img {
    position: relative;
    width: 1em;
  }

.DeliverySiteBodyElement-label {
  font-weight: 700;
}

.DeliverySiteBodyElement-subIcon {
  padding-right: 0.125em;
}

  .DeliverySiteBodyElement-subIcon img {
    position: relative;

  }

.DeliverySiteBodyElement-subtext {
  font-style: italic;
  font-size: .85rem;
  color: #757575;
  display: block;
}

.DeliverySiteBodyElement-text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
}

@media screen and (min-width: 320px) {
  .DeliverySiteBodyElement {
    flex-direction: row;
    gap: 16px;
    margin-bottom: 16px;
  }

  .DeliverySiteBodyElement-label {
    flex-basis: 6em;
  }
}

@media screen and (min-width: 768px) {
  .DeliverySiteBodyElement {
    font-size: 1.25rem;
  }

  .DeliverySiteBodyElement-label {
    flex-basis: 9em;
  }
}
