.notification-bar {
  background-image: linear-gradient(rgba(175,39,47,1),rgba(175,39,47,1), rgba(175,39,47,0));
  color: #fff;
  font-size: 2vh;
  opacity: 0.75;
  padding: 0.42vw 0 1vw 0;
  position: fixed;
  text-align: center;
  width: 100%;
}

.notification-bar-success {
  background-image: linear-gradient(rgba(39, 175, 47, 1), rgba(39, 175, 47, 1), rgba(39, 175, 47, 0));
  color: #ffffe0;
}

.notification-bar-failure {
  background-image: linear-gradient(rgba(255, 0, 0, 1), rgba(255, 0, 0, 1), rgba(255, 0, 0, 0));
  color: #ffffe0;
}

.notification-bar button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
}
