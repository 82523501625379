.DeliverySiteHeader {
  background-color: #000;
  color: #fff;
  margin-bottom: 16px;
  padding: 16px 0;
}

.DeliverySiteHeader-loading-message {
  color: #7c7979;
  font-size: .65rem;
}

.DeliverySiteHeader-title {
  font-size: 1.25rem;
  font-weight: 700;
}

.DeliverySiteHeader-wrapper {
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (min-width: 320px) {
  .DeliverySiteHeader-loading-message {
    font-size: .85rem;
  }
}

@media screen and (min-width: 768px) {
  .DeliverySiteHeader {
    margin-bottom: 32px;
    padding: 32px 0;
  }

  .DeliverySiteHeader-title {
    font-size: 2rem;
  }
}
